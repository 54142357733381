<template>
  <v-container>
    <v-navigation-drawer clipped width="auto" app>
      <v-list-item class="py-3">
        <v-list-item-content>
          <v-list-item-title class="text-h6 primary--text"><v-icon class="mr-2 primary--text">mdi-account-tie</v-icon> System Admin </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-group :value="true" prepend-icon="mdi-account" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>User Management</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(item, i) in users" :key="i" link :to="item.url">
            <v-list-item-icon>
              <v-icon class="primary--text" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text"  v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" prepend-icon="mdi-store" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Station Management</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(item, i) in stations" :key="i" link :to="item.url">
            <v-list-item-icon>
              <v-icon class="primary--text" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" prepend-icon="mdi-map-marker" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Point Of Interest Management</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(item, i) in interest" :key="i" link :to="item.url">
            <v-list-item-icon>
              <v-icon class="primary--text" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" prepend-icon="mdi-flask-outline" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Parameter Management</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(item, i) in parameters" :key="i" link :to="item.url">
            <v-list-item-icon>
              <v-icon class="primary--text" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" prepend-icon="mdi-database-cog" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Manual Data Update</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(item, i) in data" :key="i" link :to="item.url">
            <v-list-item-icon>
              <v-icon class="primary--text" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" prepend-icon="mdi-wrench-clock" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Maintenance Management</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(item, i) in maintenance" :key="i" link :to="item.url">
            <v-list-item-icon>
              <v-icon class="primary--text" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" prepend-icon="mdi-air-filter" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Calibration Management</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(item, i) in calibration" :key="i" link :to="item.url">
            <v-list-item-icon>
              <v-icon class="primary--text" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>





<script>
export default {
  data: () => ({
    drawer: false,
    users: [
      { 
        name: "User List", 
        icon: "mdi-format-list-bulleted", 
        url: '/systemAdmin/userManagement/userList' 
      },

      { 
        name: "Add User",
        icon: "mdi-account-plus", 
        url:  '/systemAdmin/userManagement/addUser' 
      }
    ],

    stations: [
      { 
        name: "Station List", 
        icon: "mdi-format-list-bulleted", 
        url: '/systemAdmin/stationManagement/stationList' 
      },
      { 
        name: "Add Station", 
        icon: "mdi-store-24-hour", 
        url: '/systemAdmin/stationManagement/addStation' 
      }
    ],

    interest: [
      { 
        name: "List of Point Interest", 
        icon: "mdi-format-list-bulleted", 
        url: '/systemAdmin/pointOfInterest/interestList' 
      },
      { 
        name: "Add Point Interest", 
        icon: "mdi-plus", 
        url: '/systemAdmin/pointOfInterest/addInterest' 
      }
    ],

    parameters: [
      { 
        name: "Parameter List", 
        icon: "mdi-format-list-bulleted", 
        url: '/systemAdmin/parameterManagement/parameterList' 
      },
      { 
        name: "Set Parameter", 
        icon: "mdi-cup-water", 
        url: '/systemAdmin/parameterManagement/addParameter' 
      },
      { 
        name: "Set Min Max", 
        icon: "mdi-tune", 
        url: '/systemAdmin/parameterManagement/setMinMax' 
      }
    ],

    data: [
      { 
        name: "Data Update", 
        icon: "mdi-database-refresh-outline", 
        url: '/systemAdmin/manualDataUpdate/dataUpdate' 
      }
    ],

    maintenance: [
      { 
        name: "Data Entry", 
        icon: "mdi-database-import-outline", 
        url: '/systemAdmin/maintenanceManagement/dataEntry' 
      },
      { 
        name: "Maintenance Record", 
        icon: "mdi-database-sync-outline", 
        url: '/systemAdmin/maintenanceManagement/OnGoing' 
      },
      { 
        name: "Historical Summary", 
        icon: "mdi-database-clock-outline", 
        url: '/systemAdmin/maintenanceManagement/Summary' 
      },
    ],

    calibration: [
      { 
        name: "Data Entry", 
        icon: "mdi-database-import-outline", 
        url: '/systemAdmin/calibrationManagement/dataEntry' 
      },
      { 
        name: "Calibration Record", 
        icon: "mdi-database-sync-outline", 
        url: '/systemAdmin/calibrationManagement/OnGoing' 
      },
      { 
        name: "Historical Summary", 
        icon: "mdi-database-clock-outline", 
        url: '/systemAdmin/calibrationManagement/Summary' 
      },
    ],

  }),

  methods: {

  },

  computed: {

  },

  mounted() {
    
  }
};
</script>

<style lang="scss" scoped>
</style>
