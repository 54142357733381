import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                // primary: '#2698FB',
                // primary: '#018abc',
                primary: '#0199be',
                greenRefresh: '#00e608',
                rightBlue: '#0184bb',
                success: '#107079',
                // error: '#e57373',
                error: '#ff3737',
            },
            dark: {},
        },
    },
    icons: {
        iconfont: 'mdi',
    },

});
