import Vue from "vue";
import VueRouter from "vue-router";
import AuthGuard from './auth-guard'

Vue.use(VueRouter)

export default new VueRouter({
    mode: "history",
    routes: [
        { 
            path: "/login",
            name: "Login",
            component: () => import('@/views/User/Login/Login'),
            meta: { showFooter: true, showLeftPanel: false },
            
        },

        { 
            path: "/",
            name: "Mapviewer",
            component: () => import('@/views/Mapviewer'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard,
        },

        { 
            path: "/dashboard",
            name: "Dashboard",
            component: () => import('@/views/Dashboard'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard,
        },
    
        { 
            path: "/about",
            name: "About",
            component: () => import('@/views/About'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard,
        },

        //Path Modul Alert
        { 
            path: "/alert/exceedance",
            name: "Exceedance Alert",
            component: () => import('@/views/Alert/Exceedance'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard,
        },
        { 
            path: "/alert/transmission",
            name: "Transmission Alert",
            component: () => import('@/views/Alert/Transmission'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard,
        },
        { 
            path: "/alert/exceedanceByParameter",
            name: "Exceeedance By Parameter Alert",
            component: () => import('@/views/Alert/ExceedanceByParameter'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },
         

        //Path Modul Report
        { 
            path: "/report/dailyReport",
            name: "Daily Report",
            component: () => import('@/views/Report/DailyReport'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard,
        },
        { 
            path: "/report/monthlyReport",
            name: "Monthly Report",
            component: () => import('@/views/Report/MonthlyReport'),   //dulu MonthlyReport2
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard,
        },
        { 
            path: "/report/yearlyReport",
            name: "Yearly Report",
            component: () => import('@/views/Report/YearlyReport'),   //dulu YearlyReport2
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },
        { 
            path: "/report/queryOptions",
            name: "Query Options",
            component: () => import('@/views/Report/QueryOptions'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },
        { 
            path: "/report/rawData",
            name: "Raw Data",
            component: () => import('@/views/Report/RawData'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },

        //Path Modul Operational
        { 
            path: "/operational/maintenance",
            name: "Maintenance",
            component: () => import('@/views/Operational/Maintenance'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },
        { 
            path: "/operational/maintenanceUpload",
            name: "Maintenance Upload",
            component: () => import('@/views/Operational/MaintenanceUpload'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },
        { 
            path: "/operational/calibration",
            name: "Calibration",
            component: () => import('@/views/Operational/Calibration'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },
        { 
            path: "/operational/calibrationUpload",
            name: "Calibration Upload",
            component: () => import('@/views/Operational/CalibrationUpload'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },
        { 
            path: "/operational/qaqcSecLevel",
            name: "QAQC 2nd Level",
            component: () => import('@/views/Operational/QAQCSecLevel'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },
        { 
            path: "/operational/validation",
            name: "Validation",
            component: () => import('@/views/Operational/Validation'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },
 
        //Path CMS Reporting
        { 
            path: "/cmsreporting/reporting",
            name: "CMS Reporting",
            component: () => import('@/views/CMSReporting/Reporting'),
            meta: { showHeader: true, showLeftPanel: false },
            beforeEnter: AuthGuard, 
        },


        //Path System Admin
        { 
            path: "/systemAdmin/userManagement/userList",
            name: "User List",
            component: () => import('@/views/SystemAdmin/UserManagement/UserList'),
            meta: { showHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard,
        },

        { 
            path: "/systemAdmin/userManagement/addUser",
            name: "Add User",
            component: () => import('@/views/SystemAdmin/UserManagement/AddUser'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        // ---------------------------

        { 
            path: "/systemAdmin/stationManagement/stationList",
            name: "Station List",
            component: () => import('@/views/SystemAdmin/StationManagement/StationList'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        { 
            path: "/systemAdmin/stationManagement/addStation",
            name: "Add Station",
            component: () => import('@/views/SystemAdmin/StationManagement/AddStation'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        // ---------------------------

        { 
            path: "/systemAdmin/pointOfInterest/interestList",
            name: "Interest List",
            component: () => import('@/views/SystemAdmin/PointOfInterest/ListInterest'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        { 
            path: "/systemAdmin/pointOfInterest/addInterest",
            name: "Add Interest",
            component: () => import('@/views/SystemAdmin/PointOfInterest/AddInterest'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        // ---------------------------

        { 
            path: "/systemAdmin/parameterManagement/parameterList",
            name: "Parameter List",
            component: () => import('@/views/SystemAdmin/ParameterManagement/ParameterList'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        { 
            path: "/systemAdmin/parameterManagement/addParameter",
            name: "Add Parameter",
            component: () => import('@/views/SystemAdmin/ParameterManagement/AddParameter'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        { 
            path: "/systemAdmin/parameterManagement/setMinMax",
            name: "Set Min Max",
            component: () => import('@/views/SystemAdmin/ParameterManagement/SetMinMax'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        // ---------------------------

        { 
            path: "/systemAdmin/manualDataUpdate/dataUpdate",
            name: "Data Update",
            component: () => import('@/views/SystemAdmin/ManualDataUpdate/DataUpdate'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        // ---------------------------

        { 
            path: "/systemAdmin/maintenanceManagement/dataEntry",
            name: "Data Entry",
            component: () => import('@/views/SystemAdmin/MaintenanceManagement/DataEntry'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        { 
            path: "/systemAdmin/maintenanceManagement/onGoing",
            name: "On Going",
            component: () => import('@/views/SystemAdmin/MaintenanceManagement/OnGoing'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },
        

        { 
            path: "/systemAdmin/maintenanceManagement/summary",
            name: "Summary",
            component: () => import('@/views/SystemAdmin/MaintenanceManagement/Summary'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        // ---------------------------

        { 
            path: "/systemAdmin/CalibrationManagement/dataEntry",
            name: "Data Entry",
            component: () => import('@/views/SystemAdmin/CalibrationManagement/DataEntry'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        { 
            path: "/systemAdmin/CalibrationManagement/onGoing",
            name: "On Going",
            component: () => import('@/views/SystemAdmin/CalibrationManagement/OnGoing'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        { 
            path: "/systemAdmin/CalibrationManagement/summary",
            name: "Summary",
            component: () => import('@/views/SystemAdmin/CalibrationManagement/Summary'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        //Path User Profile
        { 
            path: "/userProfile/userProfile",
            name: "UserProfile",
            component: () => import('@/views/UserProfile/UserProfile'),
            meta: { showHeader: true, showLeftPanel: false},
            beforeEnter: AuthGuard, 
        },

    ]
});
